/**
 * usage:
 * - 1. loadingTargetを position: relative
 * - 2. loadingTargetに中に <%= render partial: 'shared/loader' %>入れる
 *
 * @param loadingTarget
 * @param disableTargets
 * @param callback
 * @returns {Promise<null|*>}
 */
export async function withLoadingAndDisable(loadingTarget, disableTargets, callback) {
  try {
    // loading
    loadingTarget.classList.add('-loading');
    // disable
    if (disableTargets) {
      disableTargets.forEach(el => {
        el.disabled = true;
      });
    }

    const result = await callback();

    // loading off
    loadingTarget.classList.remove('-loading');
    // disable off
    if (disableTargets) {
      disableTargets.forEach(el => {
        el.disabled = false;
      });
    }

    return result;
  } catch (e) {
    console.error('error: ', e);

    // loading off
    loadingTarget.classList.remove('-loading');
    // disable off
    if (disableTargets) {
      disableTargets.forEach(el => {
        el.disabled = false;
      });
    }
    return null;
  }
}

function wait(ms) {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
}

/**
 * @param requestCb
 * @param stopConditionCb
 * @param intervalMs
 * @returns {Promise<*>}
 */
export async function pollingRequest(requestCb, stopConditionCb, intervalMs = 5000) {
  let result = await requestCb();
  while (!stopConditionCb(result)) {
    await wait(intervalMs);
    result = await requestCb();
  }
  return result;
}

/**
 * @deprecated
 * use utils/csrf.util.ts
 * jsでtsは参照はできないので、getCsrfToken()を使うjsが viteからcompileされる時だけrefactoringすること
 */
export function getCsrfToken() {
  return document.querySelector('meta[name="csrf-token"]').getAttribute('content');
}

export function jqueryAjaxBeforeSend(xhr) {
  xhr.setRequestHeader('X-CSRF-Token', getCsrfToken());
}

export function apiFailResultHandler(e, message) {
  console.error(e);
  if (e.responseJSON && e.responseJSON.status === 400) {
    alert(e.responseJSON.message);
  } else {
    alert(message);
  }
}

export async function fetchWithTimeout(resource, options = {}) {
  const { timeout = 10000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const response = await fetch(resource, {
    headers: {
      'Accept': 'application/json',
      'X-CSRF-TOKEN': getCsrfToken(),
    },
    ...options,
    signal: controller.signal
  });
  clearTimeout(id);

  return response;
}

/**
 * objectから、null, undefined, ''の値を削除して、URLSearchParamsを作成する
 * 0は残した場合があるため、truthyは使わない
 *
 * @param paramsObj
 * @returns {module:url.URLSearchParams}
 */
export function buildSafeURLSearchParams(paramsObj) {
  for (const key of Object.keys(paramsObj)) {
    if (paramsObj[key] === null || paramsObj[key] === undefined || paramsObj[key] === '') {
      delete paramsObj[key];
    }
  }
  return new URLSearchParams(paramsObj);
}
