import 'script-loader!./src/notify';
import { initExternalLinkModal } from './external_link_modal';
import { initCustomLinkMenu } from './custom_link_menu';

// Share URL Copy
$('.js-share_copy').click(function () {
  $('body').append(
    `<textarea id="currentURL" style="position:fixed;left:-100%;">${location.origin}${location.pathname}</textarea>`
  );
  $('#currentURL').select();
  document.execCommand('copy');
  $('#currentURL').remove();
  // alert("URLをコピーしました");
  $.notify('コピーしました', { position: 'top center', className: 'success' });
});

initExternalLinkModal();
initCustomLinkMenu();
