import {  jqueryAjaxBeforeSend } from "./api_utils";


export const initCustomLinkMenu = () => {
  document.addEventListener("DOMContentLoaded", () => {
    bindCustomLinkMenuEvent(document);
    bindCustomLinkPostEvent(document);
  });
};

export const bindCustomLinkMenuEvent = (el) => {
  const customeLinks = $(el).find(".st-Nav_Link[href^=http],.st-GroupedNav_Link[href^=http],.st-NavFooter_Link[href^=http]");
  customeLinks.each(function () {
    $(this).on("click", function (e) {
      $.ajax({
        type: 'GET',
        url: `/menus/${this.dataset.id}`,
        async: true,
        data: { id: this.dataset.id },
        beforeSend: jqueryAjaxBeforeSend,
      }).done(function (data) {
        ;
      });
    });
  });
};

export const bindCustomLinkPostEvent = (el) => {
  const customeLinks = $(el).find(".announcement-custom-link[href^=http]");
  customeLinks.each(function () {
    $(this).on("click", function (e) {
      $.ajax({
        type: 'GET',
        url: `/announcements/${this.dataset.id}/custom_link_postproc`,
        async: true,
        data: { id: this.dataset.id },
        beforeSend: jqueryAjaxBeforeSend,
      }).done(function (data) {
        ;
      });
    });
  });
};
